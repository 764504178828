/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2020 nic.at GmbH <https://nic.at>
 * Software-Engineering: 2020 Intevation GmbH <https://intevation.de>
 *
 * Author(s):
 * * Fadi Abbud <fadi.abbud@intevation.de>
 */
import packageInfo from "../../package.json";
import { HTTP } from "../lib/http";
import store from "@/store/index";

export const application = {
  namespaced: true,
  state: () => ({
    httpErrorMessage: "",
    version: packageInfo.version,
    showSelectNodeDialog: false,
    showRules: false,
    tenants: [],
    abuseRoles: [],
    contactRoles: [],
    contactRoleMapping: [],
    showErrorDialog: false,
    defaultNetobjectType: "asn",
    netobjectRules: []
  }),
  mutations: {
    setHttpErrorMessage: (state, message) => {
      state.httpErrorMessage = message;
    },
    setShowSelectNodeDialog: (state, show) => {
      state.showSelectNodeDialog = show;
    },
    setShowRules: (state, show) => {
      state.showRules = show;
    },
    setTenants: (state, tenants) => {
      state.tenants = tenants;
    },
    setContactRoles: (state, roles) => {
      state.contactRoles = roles;
    },
    setContactRoleMapping: (state, roleMapping) => {
      state.contactRoleMapping = roleMapping;
    },
    setShowErrorDialog: (state, value) => {
      state.showErrorDialog = value;
    },
    setDefaultNetobjectType: (state, type) => {
      state.defaultNetobjectType = type;
    },
    setNetobjectRules: (state, ruleConfig) => {
      state.netobjectRules = ruleConfig;
    }
  },
  getters: {
    // To make changes on the version number form
    appVersion: state => {
      return state.version + " rev" + process.env.VUE_APP_GIT_REV || "0";
    },
    getRolesByTenants: state => (tenants = []) => {
      const roleKeys = JSON.parse(
        JSON.stringify(state.contactRoleMapping["tenants"]["all"])
      );

      if (tenants.length) {
        tenants.forEach(tenant => {
          roleKeys.push(...state.contactRoleMapping["tenants"][tenant]);
        });
      }

      return state.contactRoles.filter(role => roleKeys.includes(role.name));
    },
    getPortalAdminRoles: state => {
      if (
        state.contactRoleMapping["client_roles"] !== undefined &&
        state.contactRoleMapping["client_roles"]["portaladmin"]
      ) {
        return state.contactRoles.filter(role =>
          state.contactRoleMapping["client_roles"]["portaladmin"].includes(
            role.name
          )
        );
      }

      return [];
    },
    getAbuseRoles: state => {
      return state.contactRoleMapping["abuse_roles"];
    },
    getFilteredAbuseContacts: state => (contacts: any = []) => {
      const filteredContacts: any = [];

      contacts.forEach(contact => {
        state.contactRoleMapping["abuse_roles"].forEach(abuseRole => {
          if (contact.roles.includes(abuseRole)) {
            filteredContacts.push(contact);
          }
        });
      });

      return filteredContacts.length ? filteredContacts : contacts;
    },
    getNetobjectRules: state => {
      return state.netobjectRules;
    }
  },
  actions: {
    getTenants({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP.get("/tenants")
          .then(response => {
            commit("setTenants", response.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
